import React, { Component, PureComponent } from 'react';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'gatsby';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';


export default function DashboardFeed({ withQuickLook }) {

    let quickLook = (
        <div className="has-text-centered">
            <QuickLook
            subtitle="Carbon Breakdown"
            targetUrl="reports"
            chartType="bar"
            apiPath="data/sustainability/carbon_benchmark.json"
            X="label"
            Y={["index"]}
            />
        </div>
    )
    let data = [
        {
            title: (<span>Data updated for 2 dashboards under <Link to="/reports">Reports</Link></span>),
            content: withQuickLook ? quickLook : null,
            datetime: 'June 2, 2022 11:34',
            icon: faChartBar
        },
    ]

    return (
        <Feed heading="Dashboard" items={data} />
    )
}
