import React, { Component, PureComponent } from 'react';
import Feed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed';
import { faArrowUp, faCalendarPlus } from '@fortawesome/free-solid-svg-icons';


export default function SourceDataFeed() {
    let data = [
        {
            title: (<span>Received data for <u>mailchimp</u></span>),
            description: (<code>s3://acme-c360-upstream/mailchimp/</code>),
            datetime: 'May 12, 2022 11:34',
            icon: faCalendarPlus
        },
        {
            title: (<span>Received data for <u>crm</u></span>),
            description: (<code>s3://acme-c360-upstream/crm/</code>),
            datetime: 'May 1, 2022 17:46',
            icon: faCalendarPlus
        },
    ]

    return (
        <Feed heading="Source Data" items={data} />
    )
}
