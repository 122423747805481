import React, { useState } from 'react';
import BaseGallery, { getPropertySortFunction } from '@tra-sg/gatsby-theme-c360-portal/src/components/BaseGallery';
import { callApi, callApiWithResult } from '@tra-sg/gatsby-theme-c360-portal/src/data/backend_api';
import { ListItemText, ListItemAvatar } from "@material-ui/core";
import DatasetInfo from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetInfo';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import QuickLook from '@tra-sg/gatsby-theme-c360-portal/src/components/QuickLook';
import TablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePreview';
import TableQuery from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableQuery';
import TablePipeline from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePipeline';
import TablePipelineInfor from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TablePipelineInfor';
import TableInOutPut from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableInOutPut';
import TableDataExploration from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableDataExploration';
import UserTablePreview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/UserTablePreview';
import UserTableSchema from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/UserTableSchema';
import TableOverview from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableOverview';
import TableTransformWizard from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/TableTransformWizard';
import DatasetRegistration from '@tra-sg/gatsby-theme-c360-portal/src/components/DatalakeBrowser/DatasetRegistration';
import { useQuery } from 'react-query';
import { faDiceD6, faTable, faDownload, faAngleRight, faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { faPlus, faRandom, faSearchPlus, faFlask } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import useCollapse from 'react-collapsed';
import { navigate } from 'gatsby';
import FilterResults from 'react-filter-search';
import SourceDataFeed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed/SourceDataFeed';
import DashboardFeed from '@tra-sg/gatsby-theme-c360-portal/src/components/Feed/DashboardFeed';
import NewNotebookModal from '@tra-sg/gatsby-theme-c360-portal/src/components/LabGallery/NewNotebookModal';


function DatasetItem({ datapackage, groups, selectedItem, setSelectedItem, sharedState, setSharedState, filterValue }) {
  const { name, title } = datapackage;

  const { getCollapseProps, getToggleProps, isExpanded } = useCollapse()

  return (
    <>
      <div>
        <div className='level' style={{marginBottom: '0.5rem'}}>
          <div className='level-left'>
            <div className='level-item' {...getToggleProps()}>
              <FontAwesomeIcon
                icon={isExpanded ? faAngleDown : faAngleRight}
                style={{fontSize: '1em', outline: 'none', marginRight: '0.5em'}}
              />
              <FontAwesomeIcon
                icon={faDiceD6}
                style={{fontSize: '1.5em', outline: 'none'}}
              />
            </div>
            <div className='level-item' onClick={() => setSharedState({})}>
              <div>
                <span className='subtitle is-size-6'>{name}</span>
                <p className='has-text-grey is-size-7'>{title}</p>
              </div>
            </div>
          </div>
        </div>
        <section {...getCollapseProps()}>
          <DatasetItemPageMenu
            datapackage={datapackage}
            setSelectedItem={setSelectedItem}
            sharedState={sharedState}
            setSharedState={setSharedState}
            filterValue={filterValue}
          />
        </section>
      </div>

    </>
  )
}

function getZoneFromResource(resource) {
  if (resource.path.length > 0) {
    if(resource.path[0].split("/").length > 1) {
      return resource.path[0].split("/")[0]
    }
  }
}


function DatasetItemMenuTableItem({ datapackage, resource, setSelectedItem, sharedState, setSharedState }) {

  const onClick = () => {
    setSelectedItem(datapackage)
    setSharedState( {...(sharedState || {}), table: resource});
  }
  return (
    <li
      className='level' style={{marginBottom: '0.25rem'}}
      onClick={onClick}
    >
      <div className='level-left'>
        <FontAwesomeIcon className='level-item' icon={faTable} />
        <a
          className={((sharedState || {}).table == resource ? 'is-active' : '') + 'level-item'}
        >{resource.name}</a>
      </div>
    </li>
  )
}

function DatasetItemPageMenu({ datapackage, selected, setSelectedItem, sharedState, setSharedState, filterValue }) {
  const { resources } = datapackage;


  return (
    <FilterResults
      value={filterValue}
      data={resources}
      renderResults={(results) => {
        const resourcesGroupByZones = {};
        results.sort(getPropertySortFunction("name", true)).forEach((r) => {
          r.zone = getZoneFromResource(r)

          if (resourcesGroupByZones[r.zone] == null) {
            resourcesGroupByZones[r.zone] = [];
          }
          resourcesGroupByZones[r.zone].push(r)
        })

        return (
          <div className='menu' style={{paddingLeft: '30px'}}>
          {
            Object.entries(resourcesGroupByZones).map(
              ([key, value]) => {
                return (
                  <>
                    <div className='menu-label'>{key}</div>
                    <ul className='menu-list'>
                      { value.map((e) => (
                          <DatasetItemMenuTableItem
                            datapackage={datapackage}
                            resource={e}
                            selected={selected}
                            setSelectedItem={setSelectedItem}
                            sharedState={sharedState}
                            setSharedState={setSharedState}
                          />
                        ))}
                    </ul>
                  </>
                )
              }
            )
          }
        </div>
        )
      }}
    />

  )
}

function navigateTab(tabIndex, tabDict) {
  let url = window.location.href.split("?")[0]
  let tab = Object.keys(tabDict).find(key => tabDict[key] === tabIndex)
  // NOTE: navigateTab currently does nothing as the tabs do not change URL, but
  // it should in the future, so this is left here.
  //
  // navigate(`${url}?tab=${tab}`)
}


function TableSchema({tableData}) {
  if (tableData) {
    if ((tableData.schema || []).length == 0) {
      return (
        <div className="columns half-vh">
          Schema is not available
        </div>
      );
    }

    if ((tableData.schema || []).length > 0) {
      return (
        <div className="columns half-vh is-mobile">
          <div className="column">
            <h6>Column</h6>
            {
                  tableData.schema.map((column) => (
                    <p className="has-text-weight-light is-size-7" key={column.column_name}>{column.column_name}</p>
                  ))
                }
          </div>
          <div className="column">
            <h6>Datatype</h6>
            {
                  tableData.schema.map((column) => (
                    <p className="has-text-weight-light is-size-7" key={column.column_name}>{column.data_type}</p>
                  ))
                }
          </div>
          <div className="column">
            <h6>Nullable</h6>
            {
                  tableData.schema.map((column) => (
                    <p className="has-text-weight-light is-size-7" key={column.column_name}>Nullable</p>
                  ))
                }
          </div>
        </div>
      );
    }

    return (
      <div className="columns half-vh">
        <div className="column has-text-centered is-10">
          <div className="iframe-holder" />
        </div>
      </div>
    )
  }
}


function TableHistoryMock({ tableinfo }) {
  if (tableinfo) {
    return (
      <div className="columns half-vh">
        <div className="column is-narrow">
          <h6>State</h6>
          <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
          <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
          <p className="has-text-weight-light is-size-7">SUCCEEDED</p>
        </div>
        <div className="column is-narrow">
          <h6>Start time</h6>
          <p className="has-text-weight-light is-size-7">2020-09-08 11:00 GMT+8</p>
          <p className="has-text-weight-light is-size-7">2020-09-07 11:00 GMT+8</p>
          <p className="has-text-weight-light is-size-7">2020-09-06 11:00 GMT+8</p>
        </div>
        <div className="column is-narrow">
          <h6>Duration (seconds)</h6>
          <p className="has-text-weight-light is-size-7">120</p>
          <p className="has-text-weight-light is-size-7">119</p>
          <p className="has-text-weight-light is-size-7">108</p>
        </div>
        <div className="column is-half">
          <div className="rows">
            <div className="row-is-full">
              <QuickLook
                chartType="line"
                apiPath="data/datalake/size_increase.json"
                X="date"
                Y={['size']}
                chartHeight={140}
              />
            </div>
            <div className="row-is-full">
              <QuickLook
                chartType="line"
                apiPath="data/datalake/size_increase.json"
                X="date"
                Y={['row']}
                chartHeight={140}
              />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="columns half-vh">
        History is not available
      </div>
    );
  }
}


function DatasetTablePageTransformModal({
  dataset_id, zone_id, group_id,
  tableinfo, transformActive, toggleTransformModal,
}) {
    let table_id = tableinfo ? tableinfo.name : null

    // if (!tableinfo.schema) return (<div>Schema not available...</div>)
    return (
      <div className={"modal " + (transformActive ? "is-active" : "")} >
        <div className="modal-background" onClick={toggleTransformModal}/>
        <div className="modal-card" style={{width: "80vw", height: "80vh", margin: "15px", "padding-top": "30px", "padding-top": "30px"}}>
          <div className="modal-card-body">
          <TableTransformWizard
            dataset_id={dataset_id} table_id={table_id} zone_id={zone_id} group_id={group_id}
            schema={tableinfo.schema ? tableinfo.schema.fields : null}
            closeWindow={toggleTransformModal}
            // manualNavigate={this.manualNavigate}
          />
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={toggleTransformModal}></button>
      </div>
    )
}


function TableHeader(props) {
  const [ hoveredButtonDesc, setHoveredButtonDesc ] = useState(" ");
  const { dataset_id, zone_id, table_id, group_id, isLoading, generatingPresignedUrl, downloadTable, toggleTransformModal } = props;


  const [exploreModalActive, setExploreModalActive] = useState(false);

  function navigateToExperiment() {
    // TODO: make experiment page its own page
    const table_id_underscore = table_id.replace(/\./g,'_')
    const experimentUrl = `/labs?task=experiment&data_source=${dataset_id}.${table_id_underscore}&zone=${zone_id}&group_id=${group_id}`
    console.log("Navigate to", experimentUrl)
    navigate(experimentUrl);
  }

  return (
    <div>
      <div className="level" style={{marginBottom: '0.1rem'}}>
        <div className="level-left">
          <div className="level-item">
            <h4 className="title">{table_id}</h4>
          </div>
        </div>
        <div className="level-right">
          <div className="level-item">
            <button
                className={`button is-primary is-light ${generatingPresignedUrl ? 'is-loading' : ""}`}
                aria-haspopup="true" aria-controls="dropdown-menu"
                onClick={() => downloadTable()}
                onMouseEnter={() => setHoveredButtonDesc("Download this table.")}
                onMouseLeave={() => setHoveredButtonDesc(" ")}
              >
              <span><FontAwesomeIcon icon={faDownload} /></span>
              <span>{" Download"}</span>
            </button>
          </div>
          <div className="level-item">
            <button
                className="button is-primary is-light"
                onMouseEnter={() => setHoveredButtonDesc("Explore this table in a new Python notebook.")}
                onMouseLeave={() => setHoveredButtonDesc(" ")}
                onClick={() => {
                  setExploreModalActive(!exploreModalActive);
                }}
              >
              <span><FontAwesomeIcon icon={faSearchPlus} /></span>
              <span>{" Explore"}</span>
              <NewNotebookModal
                title="Explore in a Notebook"
                isActive={exploreModalActive}
                closeModalFn={() => setExploreModalActive(false)}
                />
            </button>
          </div>
          <div className="level-item">
            <button
                className="button is-primary is-light"
                onMouseEnter={() => setHoveredButtonDesc("Create a new table by transforming this table.")}
                onClick={() => toggleTransformModal()}
                onMouseLeave={() => setHoveredButtonDesc(" ")}
              >
              <span><FontAwesomeIcon icon={faRandom} /></span>
              <span>{" Transform"}</span>
            </button>
          </div>
          <div className="level-item">
            <button
                className="button is-primary is-light"
                onMouseEnter={() => setHoveredButtonDesc("Start a machine learning experiment with this table.")}
                onMouseLeave={() => setHoveredButtonDesc(" ")}
                onClick={() => navigateToExperiment()}
              >
              <span><FontAwesomeIcon icon={faFlask} /></span>
              <span>{" Experiment"}</span>
            </button>
          </div>

        </div>
      </div>
      <div className="level">
        <div className="level-left" />
        <div className="level-right">
          <div className="level-item">
          <span className="is-primary">{hoveredButtonDesc}</span>
          <span style={{color: "white"}}>.</span>
          </div>
        </div>

      </div>
    </div>
  )
}


function TableDetailMinimal({ tableinfo, tableData }) {

  function convertTableSize(size) {
    // ref https://stackoverflow.com/a/20732091
    var i = size == 0 ? 0 : Math.floor( Math.log(size) / Math.log(1024) );
    return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i];
  }

  function parseDate(dateString) {
    // this is due to the dataresource.json saving date as a string of YYYYMMDD_HHMMSS
    // which does not conform to frictionless data's date format
    // (see https://specs.frictionlessdata.io/data-package/#created)
    // TODO: fix the dataresource.json to save the correct format
    // then we don't need this anymore
    if (dateString) {
      let str_date = `${dateString.substring(0,4)}-${dateString.substring(4,6)}-${dateString.substring(6,8)} ${dateString.substring(9,11)}:${dateString.substring(11,13)}:${dateString.substring(13,15)} UTC`
      return (new Date(Date.parse(str_date))).toLocaleString();
    } else {
      return `Not available`
    }
  }

  function numberWithCommas(x) {
    return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  }


  if (tableinfo) {
    return (
      <div className="columns half-vh is-mobile">
        <div className="column is-narrow">
          <p className="has-text-weight-bold is-size-7">Size:</p>
          <p className="has-text-weight-bold is-size-7">Row count:</p>
          <p className="has-text-weight-bold is-size-7">Last modified:</p>
          <p className="has-text-weight-bold is-size-7">Input tables:</p>
          <p className="has-text-weight-bold is-size-7">Output tables:</p>
        </div>
        <div className="column">
          <p className="has-text-weight-light is-size-7">{tableinfo.bytes ? convertTableSize(tableinfo.bytes) : "Not available"}</p>
          <p className="has-text-weight-light is-size-7">{tableinfo.rows ? numberWithCommas(tableinfo.rows) : "Not available"}</p>
          <p className="has-text-weight-light is-size-7">{tableinfo.lastModified ? parseDate(tableinfo.lastModified) : "Not available"}</p>
          <TableInOutPut tableData={tableData}/>
        </div>
      </div>
    );
  } else {
    return (
      <div className="columns half-vh is-mobile">
        Table detail is not available
      </div>
    );
  }
}



function DatasetItemPageTable({ datapackage, groups, sharedState }) {

  const tableinfo = sharedState.table;
  const dataset_id = datapackage.name;
  const zone_id = tableinfo.path[0].split("/")[0];
  const table_id = tableinfo.name;
  const group_id = groups;
  const apiUrl = `dataset/table/pipeline_info?dataset=${dataset_id}&zone=${zone_id}&table=${table_id}`;
  const overviewApiUrl = `dataset/table/get?table_name=${table_id}&dataset_name=${dataset_id}&zone=${zone_id}&groups=${group_id}`;
  const downloadApiUrl = `dataset/table/get_presigned_url?dataset=${dataset_id}&zone=${zone_id}&table=${table_id}&groups=${group_id}`;

  const [transformActive, setTransformActive] = useState(false);
  const { isLoading, isError, data, error } = useQuery(
    [apiUrl, {}],
    async () => {
      const result = await callApiWithResult(apiUrl);
      return result.data
    }
  )
  const ovQuery = useQuery(
    [overviewApiUrl, {}],
    async () => {
      const result = await callApiWithResult(overviewApiUrl);
      return result;
    }
  )
  const downloadTableUrlQuery = useQuery(
    [downloadApiUrl, {}],
    async() => {
      const result = await callApiWithResult(downloadApiUrl);
      return result;
    }
  )

  if (isLoading) {
    return (
      <div className=''>
        <div className="columns is-centered">
          <div className="column has-text-centered is-10">
            <div className="iframe-holder" />
          </div>
        </div>
      </div>
    );
  }



  var commonDataset = false;
  if (groups) {
    if (groups.length > 0) {
      if (groups[0] == "common") {
        commonDataset = true;
      }
    }
  }


  const urlParams = new URLSearchParams(window.location.search);
  let myParam = urlParams.get('tab');
  if (myParam) {
    myParam = myParam.toLowerCase()
  }
  let tabDict = {
    "overview": 0,
    "analysis": 1,
    "schema": 2,
    "preview": 3,
    "pipeline": 4,
    "history": 5,
    "dataexploration": 6
  }
  let currentTab = 0
  if (!tabDict[myParam]) {
    currentTab = 0
  } else {
    currentTab = tabDict[myParam]
  }
  const tableId = tableinfo.name;

  function downloadTable() {
    // should only be called when the downloadTableQuery is ready.
    if (downloadTableUrlQuery.isLoading) {
      // error - button should only be available if table URL is ready
      console.log("Table URL not ready!")
    } else if (downloadTableUrlQuery.error) {
      console.log("Something went wrong while fetching download url")
    } else {
      console.log("Downloading", downloadTableUrlQuery.data.presigned_url)
      navigate(downloadTableUrlQuery.data.presigned_url, '_blank');
    }
  }

  var currentDate = new Date();
  var currentDateString = currentDate.toISOString().replace(/-/g, '').split('T')[0];

  if (!ovQuery.isLoading) {
    currentDateString = ovQuery.data.lastModified.split('_')[0];
  }

  return (
    <>
      <TableHeader
        dataset_id={dataset_id}
        table_id={table_id}
        zone_id={zone_id}
        group_id={group_id}
        downloadTable={downloadTable}
        generatingPresignedUrl={downloadTableUrlQuery.isLoading}
        toggleTransformModal={() => setTransformActive(!transformActive)}
      />
      <Tabs
        defaultIndex={currentTab}
        onSelect={index => navigateTab(index, tabDict)}
        forceRenderTabPanel={false} // This forces ALL tabs to be loaded to DOM,
                                    // therefore component fetching will happen in parallel.
      >
        <TabList>
          <Tab>Overview</Tab>
          <Tab>Analysis</Tab>
          <Tab>Schema</Tab>
          <Tab>Preview</Tab>
          <Tab disabled={!commonDataset}>Pipeline</Tab>
          <Tab disabled={!commonDataset}>History</Tab>
        </TabList>
        <TabPanel>
          {
            ovQuery.isLoading ?
            (
              <div className="columns is-centered">
                <div className="column has-text-centered is-10">
                  <div className="iframe-holder" />
                </div>
              </div>
            )
            : (<TableOverview
                dataset_id={dataset_id}
                tableId={table_id}
                zone={zone_id}
                groups={group_id}
                data={ovQuery.data}
                isEditing={false}
              />)
          }
        </TabPanel>
        <TabPanel>
          {!commonDataset
            ?
            (<TableDataExploration
                dataset_id={dataset_id}
                table_id={table_id}
                zone_id={zone_id}
                group_id={group_id}
                isLoading={false}
              />)
            :
            (<TableDetailMinimal
              tableinfo={tableinfo}
              tableData={data}
            />)
          }
        </TabPanel>
        <TabPanel>
          {!commonDataset
            ?
            (<UserTableSchema
                dataset_id={dataset_id}
                table_id={table_id}
                zone_id={zone_id}
                group_id={group_id}
              />)
            :
            (<TableSchema tableData={data} />)
          }
        </TabPanel>
        <TabPanel>
          {/* {this.disabledIfNotCommonDataset() ? this.renderUserTablePreview() : this.renderTablePreview()} */}
          {!commonDataset ?
            (<UserTablePreview
              dataset_id={dataset_id}
              table_id={table_id}
              zone_id={zone_id}
              group_id={group_id}
            />)
            :
            (<TablePreview
                table_id={table_id}
                dataset_id={dataset_id}
                zone_id={zone_id}
                schema={(tableinfo.schema || {}).fields}
              />)
          }
        </TabPanel>
        <TabPanel>
          <div className="half-vh">
            <TablePipeline tableData={data} date={currentDateString}/>
            <TablePipelineInfor tableData={data} date={currentDateString}/>
            <br/>
            <h2>Query</h2>
            <TableQuery tableData={data}/>
          </div>
        </TabPanel>
        <TabPanel>
          <TableHistoryMock tableinfo={tableinfo} />
        </TabPanel>
      </Tabs>
      <DatasetTablePageTransformModal
          dataset_id={dataset_id}
          zone_id={zone_id}
          group_id={group_id}
          tableinfo={tableinfo}
          transformActive={transformActive}
          toggleTransformModal={() => setTransformActive(!transformActive)}
      />
    </>

  );
}

function DatasetItemPage({ datapackage, groups, sharedState }) {

  return (
    <div sdtyle={{maxWidth: '100%', overflowY: 'auto'}}>
      <div className='columns'>
        <div className='column is-12'>
          {
            (datapackage.resources.includes((sharedState || {}).table)) ?
              (<DatasetItemPageTable datapackage={datapackage} sharedState={sharedState} groups={groups}/>)
              :
              (<DatasetInfo data={datapackage} isEditing={false} />)
          }
        </div>
      </div>
    </div>
  )
}


function DatasetCreateComponent({ setIsCreating }) {
  return (
    <DatasetRegistration
      onEndAddingDataset={() => setIsCreating(false)}
    />
  )
}

function NoSelectionRender({ data }) {

  var tableCount = 0;
  var tableSize = 0;

  data.forEach((dtpkg) => {
    tableCount += dtpkg.datapackage.resources.length;
    dtpkg.datapackage.resources.forEach((table) => {
      tableSize += table.bytes;
    })
  })

  return (
    <div className='section'>
      <div className=''>
          <p className='is-size-3'>
            <span>
              <FontAwesomeIcon
                icon={faDiceD6}
                style={{ color: 'lightgrey', marginRight: '20px' }}
              />
            </span>
            Dataset Browser
          </p>
      </div>
      <div style={{backgroundColor: '#eeeeee', margin: '10px 0px'}}>
          <div className='columns has-text-centered' style={{margin: '12px'}}>
            <div className='column is-3'>
              <div className='box'>
                <p className='is-size-5'>{data.length}</p>
                <p className='is-size-6'>Datasets</p>
              </div>
            </div>
            <div className='column is-3'>
              <div className='box'>
                <p className='is-size-5'>{tableCount}</p>
                <p className='is-size-6'>Tables</p>
              </div>
            </div>
            <div className='column is-3'>
              <div className='box'>
                <p className='is-size-5'>6</p>
                <p className='is-size-6'>Pipelines</p>
              </div>
            </div>
            <div className='column is-3'>
              <div className='box'>
                <p className='is-size-5'>{(tableSize / (1024 * 1024)).toFixed(1)} MB</p>
                <p className='is-size-6'>Total Lake Size</p>
              </div>
            </div>
          </div>
      </div>
      <div className='columns'>
        <div className='column is-half'>
          <DashboardFeed />
        </div>
        <div className='column is-half'>
          <SourceDataFeed />
        </div>
      </div>
    </div>
  )
}


export default function DatasetGallery({ location }) {
  return (
    <BaseGallery
      name='dataset'
      query={{
        key: [`dataset/list`, {}],
        method: async () => await callApiWithResult(`dataset/list`)
      }}
      mainKey={"datasets"}
      layout="menubar"
      CatalogItemRender={DatasetItem}
      CatalogPageRender={DatasetItemPage}
      CatalogEmptyPageRender={NoSelectionRender}
      CatalogCreateRender={DatasetCreateComponent}
      sortFieldMapping={{
        "name": "name",
      }}
      style={{maxWidth: "80vw"}}
      identityKeyFunc={(e) => ([...e.groups, e.datapackage.name]).join('/')}
      initialOpenNodes={["common"]}
      descending={true}
      rootUrl="datasets/"
      selectorFromSuffix={(childUrl, data) => {
        var suffixToken = childUrl.split("/");
        console.log("suffix token", suffixToken, suffixToken.size);
        if (suffixToken.length == 0) return;

        var selectedItem;
        var sharedState;
        if (suffixToken.length >= 1) {
          data.forEach((dataset)=> {
            console.log("checking", suffixToken, dataset)
            if (dataset.datapackage.name == suffixToken[0]) {
              selectedItem = dataset;
            }
          })
        }

        if (suffixToken.length >= 2) {
          selectedItem.datapackage.resources.forEach((r) => {
            console.log("checking", suffixToken, r);
            if (r.name == suffixToken[1]) {
              sharedState = {
                table: r,
              }
            }
          })
          if(sharedState == null) {
            console.log("Table not found in dataset", suffixToken[1]);
          }
        }

        return { selectedItem, sharedState};
      }}
      location={location}
    />
  )
}

export {
  DatasetItem
}
